.tapcb-wrapper {
  display: flex;
  margin: -0.5rem 1rem 0 0;
  align-items: center;
  justify-content: center;
}

.approving {
  margin-left: 5px;
}
.terms_of_use {
  font-family: Almoni;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
}
.private_policy {
  font-family: Almoni;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 2px;
}
