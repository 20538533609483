.react-modal-content {
  position: absolute;
  height: 54%;
  width: 50%;
  top: 23%;
  bottom: 23%;
  left: 25%;
  right: 25%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.modal-overlay {
  position: absolute;
  height: 768px;
  width: 1024px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 101;
}
.big-react-modal {
  width: 90%;
  height: 90%;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
}
.medium-react-modal {
  width: 75%;
  height: 70%;
  top: 15%;
  bottom: 15%;
  left: 12.5%;
  right: 12.5%;
}
.order-details-modal {
  width: 55%;
  height: 54%;
  top: 23%;
  bottom: 23%;
  left: 22.5%;
  right: 22.5%;
}
.tall-modal {
  width: 50%;
  height: 72%;
  top: 14%;
  bottom: 14%;
  left: 25%;
  right: 25%;
}
