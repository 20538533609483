.new-order-modal-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.new-order-modal-btn {
  width: 145px;
}

.new-order-modal-title {
  font-family: AlmoniTzar;
  font-size: 32px;
  /* margin-bottom: 1rem; */
}
.new-order-modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.new-order-modal-text {
  font-family: Almoni;
  margin: auto;
}
